.chart-container {
    width: 100%;
    height: 100%;
    /*position: relative;*/
    /*overflow-x: auto; !* Make the container scrollable if content overflows *!*/
    /*overflow-y: auto; !* Make the container scrollable if content overflows *!*/

    flex-grow: 1; /* Let chart-container take remaining space */
    display: flex;
    justify-content: center;
    align-items: center;
}

.chart-container canvas {
    height: 100% !important;
}

/*.widget-config {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*    gap: 10px;*/
/*    align-items: center;*/
/*    margin-bottom: 20px;*/
/*}*/

.widget-config {
    display: flex;
    /*justify-content: space-between;*/
    /*align-items: flex-start; !* Align elements at the start vertically *!*/
    /*gap: 20px;*/
    margin-bottom: 20px;
/*/    display: flex;*/
    justify-content: space-between;
    /*padding: 10px;*/

        /*display: flex;*/
        /*flex-direction: row;*/
        /*flex-wrap: wrap;*/
        /*gap: 10px;*/
        /*align-items: center;*/
        /*margin-bottom: 20px;*/
}

.widget-config .config-left {
    flex-grow: 1; /* Make the left section expand to fill available space */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    /*margin-bottom: 20px;*/
}

.widget-config .config-right {
    flex-shrink: 0; /* Prevent the right section from shrinking */
    /*width: 300px; !* Set a fixed width for the date picker *!*/
    /*width: 300px; !* Fixed width for the right side *!*/
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */

}

.widget-config label {
    margin-bottom: 0;
}
