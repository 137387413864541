.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 60px;
    max-width: 100%;
    margin: 20px 0 auto auto;
    padding: 0 10px; /* was: 20px */
    box-sizing: border-box;
    /*border: 1px solid #ccc;*/
}

.widget-container {
    overflow-y: auto;
    max-height: 400px;
    width: 100%;
    padding: 0 3px 3px 3px;
    /*padding: 3px;*/
}

/*.widget-container MuiPaper-root {*/
/*    padding: 5px;*/
/*}*/

/*.projects-table {*/
/*    width: 100%;*/
/*    border-collapse: collapse;*/
/*    table-layout: auto; !* Make the table more dense horizontally *!*/
/*}*/

/*.projects-table th, .projects-table td {*/
/*    border: 1px solid #ddd;*/
/*    padding: 4px 8px; !* Reduced padding for denser layout *!*/
/*    white-space: nowrap; !* Ensure no wrapping for horizontal scroll *!*/
/*}*/

/*.projects-table th {*/
/*    padding-top: 8px;*/
/*    padding-bottom: 8px;*/
/*    text-align: left;*/
/*    background-color: #f2f2f2;*/
/*}*/

/*.projects-table td:first-child, .projects-table th:first-child {*/
/*    padding-left: 8px; !* Add left padding to the first column *!*/
/*}*/

/*.projects-table th {*/
/*    background-color: #f2f2f2;*/
/*    text-align: left;*/
/*    font-weight: bold;*/
/*}*/
